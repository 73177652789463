import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import '@/scss/main.scss';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fa1, fa2, fa3, fa4, fa5, faCheck } from '@fortawesome/free-solid-svg-icons'
import { faDiscord, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faDiscord, faTelegram, faTwitter, fa1, fa2, fa3, fa4, fa5, faCheck)


const app = createApp(App)

app.use(store)
app.use(router)
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')

import "bootstrap/dist/js/bootstrap.js";
