<template>

  <!-- Start preloader -->
  <div id="preloader"></div>
  <!-- End preloader -->

  <!-- Top scroll -->
  <div class="top-scroll transition">
    <a href="#banner" class="scrollTo" v-on:click="showEasterVideo = true"><i class="fa fa-angle-up" aria-hidden="true"></i></a>
  </div>	
  <!-- Top scroll End -->

  <header class="transition">
    <div class="container">
      <div class="row flex-align">
        <div class="col-lg-4 col-md-3 col-8">
          <div class="logo">
            <a href="#banner"><img src="images/logo.png" class="transition" alt="KEKW" style="max-height: 50px;"></a>
          </div>
        </div>
        <div class="col-lg-8 col-md-9 col-4 text-right">
          <div class="menu-toggle">
            <span></span>
          </div>
          <div class="menu">
            <ul class="d-inline-block">
              <li><a href="https://t.me/kekwcoincommunity" target="_blank"><font-awesome-icon icon="fa-brands fa-telegram" /></a></li>
              <li><a href="https://discord.gg/X6UwNh5rzk" target="_blank"><font-awesome-icon icon="fa-brands fa-discord" /></a></li>
              <li><a href="https://twitter.com/TheKekwCoin" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>

  <section class="home-banner parallax" id="banner">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 position-u flex-align wow fadeInLeft">
          <div class="banner-contain">
            <h1 class="banner-heading">Welcome to the $KEKW community!</h1>
            <p class="banner-des">Where we embrace the spirit of the $KEKW!</p>
            <a href="https://app.uniswap.org/#/tokens/ethereum/0x1b9905cb8ba1d8dd77e356285490b562152bbfcb" target="_blank" class="btn">UNISWAP CHART</a>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 position-u wow fadeInRight">
          <div class="banner-img">
            <img src="gif/kekw-kek.gif" alt="banner" style="border-radius: 50%;">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section v-if="showEasterVideo" class="skyblue ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-md-12 wow fadeInUp">
          <div class="section-heading text-center pb-65">
            <label class="sub-heading">EASTER EGG</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/f-UIBVsRSDQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </section>

  <section class="work-part darkblue ptb-100" id="work-part">
    <div class="container">
      <div class="row">
        <div class="col-md-12 wow fadeInUp">
          <div class="section-heading text-center pb-65">
            <label class="sub-heading">what is $KEKW</label>
            <h2 class="heading-title">WHY KEKW?</h2>
            <p class="heading-des">The $KEKW community cherishes the laughter and joy of the iconic El Risitas! Our talented developers crafted a genuine meme coin to remind us all that even when times are tough, there's always an opportunity to look back and share a hearty KEKW!</p>
            <h4 class="pt-3">Embrace the spirit of the $KEKW and let the giggles guide you!</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 text-center flex-align justify-center wow fadeInLeft">
          <div class="work-box">
            <div class="work-box-bg"></div>
            <img src="images/work-process.png" class="rotation-img" alt="Work Process">
          </div>
        </div>
        <div class="col-md-6 flex-align wow fadeInRight">
          <div class="work-box">
            <h3 class="work-process-title pb-25">HOW TO BE PART OF THE KEKW COMMUNITY?</h3>

            <ul class="check-list">
              <li><p>CONTRACT COMING SOON</p></li>
              <li><span><font-awesome-icon icon="fa-solid fa-1" /></span> <p>Use an existing or new ERC20 wallet, like metamask... <a href="https://metamask.io/" target="_blank">https://metamask.io/</a> Also available at the App store of Google Play</p></li>
              <li><span><font-awesome-icon icon="fa-solid fa-2" /></span> <p>Add ETH to wallet.</p></li>
              <li><span><font-awesome-icon icon="fa-solid fa-3" /></span> <p>Go to <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x1b9905cb8ba1d8dd77e356285490b562152bbfcb" target="_blank" class="btn p-1">UNISWAP</a> and enter following contract address: 0x1b9905cb8ba1d8dd77e356285490b562152bbfcb and click 'I Understand'</p></li>
              <li><span><font-awesome-icon icon="fa-solid fa-4" /></span> <p>Swap Eth for KEKW, click 'approve' and 'Confirm'. </p></li>
              <li><span><font-awesome-icon icon="fa-solid fa-5" /></span> <p>Welcome to the KEKW Community!</p></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="about" class="faq-part token-sale darkblue parallax-2 pt-100">
    <div class="container">
      <div class="row">
        <div class="col-md-12 wow fadeInUp">
          <div class="section-heading text-center pb-65">
            <label class="sub-heading">ABOUT</label>
            <h2 class="heading-title">The Ultimate Crypto Giggle: $KEKW! 🚀🌕</h2>
          </div>
        </div>
        <div class="col-md-12 wow fadeInUp">
          <h6 class="pb-3">As countless meme coins juggle and fumble, the iconic El Risitas - the laughter legend behind "KEKW" - steps up to spread joy in the crypto universe and claim the meme coin throne.</h6>
          <h6 class="pb-3">$KEKW is the game-changer that's here to redefine meme coins. With a discreet launch, no presale, zero taxes, burnt LP, and renounced contract, $KEKW stands as a people's coin for eternity. Harnessing the power of El Risitas' contagious laughter, $KEKW lights the way to a world filled with humor and success.</h6>
          <h6 class="pb-3">Become a part of the $KEKW family and let's chuckle our way to the stars! 🤣🚀</h6>
        </div>
      </div>
    </div>
  </section>

  <section class="timeline skyblue bg-pattern ptb-100" id="timeline">
    <div class="container">
      <div class="row">
        <div class="col-md-12 wow fadeInUp">
          <div class="section-heading text-center pb-65">
            <label class="sub-heading">roadmap</label>
            <h2 class="heading-title">The Timeline</h2>
          </div>
        </div>
      </div>
      <div class="main-roadmap">
        <div class="row">
          <div class="col-md-12">
            <div class="h-border wow fadeInLeft"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="roadmap-slider owl-carousel">
              <div class="roadmap wow fadeInLeft">
                <div class="roadmap-box text-center">
                  <div class="date-title">PHASE 1</div>
                  <div class="map-graphic">
                    <div class="small-round"><span></span></div>
                    <div class="v-row"></div>
                  </div>
                  <div class="roadmap-detail-box">
                    <p>Smile phase</p>
                  </div>
                </div>
              </div>
              <div class="roadmap wow fadeInLeft">
                <div class="roadmap-box text-center">
                  <div class="date-title">PHASE 2</div>
                  <div class="map-graphic">
                    <div class="small-round"><span></span></div>
                    <div class="v-row"></div>
                  </div>
                  <div class="roadmap-detail-box">
                    <p>Laugh phase</p>
                  </div>
                </div>
              </div>
              <div class="roadmap wow fadeInLeft">
                <div class="roadmap-box text-center">
                  <div class="date-title">PHASE 3</div>
                  <div class="map-graphic">
                    <div class="small-round"><span></span></div>
                    <div class="v-row"></div>
                  </div>
                  <div class="roadmap-detail-box">
                    <p>Giggles phase</p>
                  </div>
                </div>
              </div>
              <div class="roadmap wow fadeInLeft">
                <div class="roadmap-box text-center">
                  <div class="date-title">PHASE 4</div>
                  <div class="map-graphic">
                    <div class="small-round"><span></span></div>
                    <div class="v-row"></div>
                  </div>
                  <div class="roadmap-detail-box">
                    <p>
                      <ul>
                        <li>MOON!</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="features" class="feature-part darkblue pt-100 pb-10">
    <div class="container">
      <div class="row">
        <div class="col-md-6 wow fadeInUp pb-80">
          <div class="feature-box">
            <div class="feature-icon">
              <img class="gif-phase" src="gif/kekw-phase-1.gif" alt="Smile phase">
            </div>
            <div class="feature-contain pt-25">
              <a href="#features" class="feature-title">SMILE PHASE</a>
              <p class="feature-des">
                <ul>
                  <li><span><font-awesome-icon icon="fa-solid fa-check" /></span> Build our Telegram Community</li>
                  <li><span><font-awesome-icon icon="fa-solid fa-check" /></span> Build official KEKW Discord</li>
                  <li><span><font-awesome-icon icon="fa-solid fa-check" /></span> Website launch</li>
                  <li>TOKEN LAUNCH</li>
                  <li>1000 Community members</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 wow fadeInUp pb-80">
          <div class="feature-box">
            <div class="feature-icon">
              <img class="gif-phase" src="gif/kekw-phase-2.gif" alt="Laugh phase">
            </div>
            <div class="feature-contain pt-25">
              <a href="#features" class="feature-title">LAUGH PHASE</a>
              <p class="feature-des">
                <ul>
                  <li>Exclusive discord channel opens for first 1000 holders</li>
                  <li>HUGE Marketing campaign</li>
                  <li>Coin Market Cap / Coingecko</li>
                  <li>Partnership announcement</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 wow fadeInUp pb-80">
          <div class="feature-box">
            <div class="feature-icon">
              <img class="gif-phase" src="gif/kekw-phase-3.gif" alt="Giggles phase">
            </div>
            <div class="feature-contain pt-25">
              <a href="#features" class="feature-title">GIGGLES PHASE</a>
              <p class="feature-des">
                <ul>
                  <li>'Las Paelleras game' token burn raffle game</li>
                  <li>KEKW merch</li>
                  <li>CEX Listing</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6 wow fadeInUp pb-80">
          <div class="feature-box">
            <div class="feature-icon">
              <img class="gif-phase" src="gif/kekw-phase-4.gif" alt="Moon phase">
            </div>
            <div class="feature-contain pt-25">
              <a href="#features" class="feature-title">MOON!</a>
              <p class="feature-des">MOON!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="tokensale-part" class="token-sale skyblue parallax-2 ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-md-12 wow fadeInUp">
          <div class="section-heading text-center pb-65">
            <label class="sub-heading">$KEKW share</label>
            <h2 class="heading-title">Tokenomics</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 wow fadeInLeft flex-align">
          <div class="token-graphic-detail">
            <ul>
              <li class="color-code-1">420,000,000,000,000 KEKW</li>
              <li class="color-code-5">95.1% Sale</li>
              <li class="color-code-2">69,420 Tokens Burned</li>
              <li class="color-code-3">0.69% Giveaways</li>
              <li class="color-code-4">4.20% Marketing / CEX allocation</li>
            </ul>
          </div>
        </div>
        <div class="col-6 flex-align justify-center-r">
          <div class="token-graph w-100">
                <div class="graph-logo">
                  <img src="images/graph-logo.png" alt="tokenomics">
                </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <footer class="bg-pattern darkblue ptb-100">
    <div class="container">
      <div class="footer">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="footer-logo pb-25">
            <a href="#banner"><img src="images/logo.png" alt="KEKW"></a>
          </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="footer-link">
              <ul>
                <li><a href="#about">About</a></li>
                <li><a href="#timeline">Roadmap</a></li>
                <li><a href="#tokensale-part">Tokenomics</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="footer-icon">
              <ul>
                <li><a href="https://t.me/kekwcoincommunity" target="_blank"><font-awesome-icon icon="fa-brands fa-telegram" /></a></li>
                <li><a href="https://discord.gg/X6UwNh5rzk" target="_blank"><font-awesome-icon icon="fa-brands fa-discord" /></a></li>
                <li><a href="https://twitter.com/TheKekwCoin" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>



<script>

export default {
    data() {
        return {
            showEasterVideo: false,
        };
    },

    created() {
    },

    computed: {
    },

    watch: {
    },

    methods: {
    },
    
    mounted() {
    },
};
</script>